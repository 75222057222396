import { configureStore } from "@reduxjs/toolkit";
import { pageReducer } from "./Reducers/Page";
import { userReducer } from "./Reducers/User";
import { domainReducer } from "./Reducers/Domain";

const store = configureStore({
  reducer: {
    user: userReducer,
    page: pageReducer,
    domain: domainReducer,
  },
});

export default store;