
import { BrowserRouter, Route, Routes } from "react-router-dom";
import LoginPage from './pages/LoginPage';
import Dashboard from './pages/Dashboard';
import { useSelector, useDispatch } from 'react-redux'

import Editor from './Editor';
import LandingPages from "./pages/LandingPages";
import PageEditor from "./PageEditor";
import { useEffect } from "react";
import { getUser } from "./Actions/User";
import { loadPageList } from "./Actions/Page";
import Domain from "./pages/Domain";
import { loadDomainList } from "./Actions/Domain";
import DB_HEADER from "./components/dashboard/DB_HEADER"


function App() {

  const { isAuthenticated } = useSelector((state) => state.user);
  let { loading } = useSelector((state) => state.page)
  const { loading:userLoading } = useSelector((state) => state.user)
  const {loading:domainLoading }= useSelector((state)=> state.domain)

  const dispatch = useDispatch();


  useEffect(() => {

    if (!loading || userLoading || domainLoading) {
      dispatch(getUser());
      dispatch(loadPageList());
      dispatch(loadDomainList());
    }
  }, [dispatch]);




  return (
    <>

      {
        loading || userLoading || domainLoading ?
          <div className="bg-gray-300/10 absolute z-10 h-screen w-screen"></div>
          :
      
      <BrowserRouter>
        <Routes>
          <Route path='/' element={isAuthenticated ? <Dashboard /> : <LoginPage />} />
          <Route path='/dashboard' element={<Dashboard />} />
          <Route path='/landing-pages' element={<LandingPages />} />
          <Route path='/editor/:id' element={<Editor />} />
          <Route path="/new/:id" element={<PageEditor />} />
          <Route path="domain" element={<Domain />} />
        </Routes>
      </BrowserRouter>
      }
    </>
  );
}

export default App;
