import React, { useEffect, useState } from 'react'
import { loadDomainList } from '../Actions/Domain';
import DB_Header from '../components/dashboard/DB_HEADER'
import { useSelector, useDispatch } from 'react-redux';
import DOMAIN_LIST from '../components/dashboard/DOMAIN_LIST';
import ADD_DOMAIN_POPUP from '../components/dashboard/ADD_DOMAIN_POPUP'
import DB_HEADER from '../components/dashboard/DB_HEADER';

const Domain = () => {

    const [open, setOpen] = useState(false)

    const domainList = useSelector((state) => state?.domain?.domainList?.data?.domains);

    const popup_handler = () => {
        if(open)
            return setOpen(false)
        return setOpen(true)
    }


    return (
        <>
        <DB_HEADER/>
            <div class="container mx-auto py-4 px-3 md:px-0">
                <div className="columns-2  mb-9">
                    <div className="heading-container">
                        <h3 className="md:text-3xl text-xl mt-2 mb-6">Domains</h3>
                    </div>
                    <div className="create-container flex justify-end">
                        <button className='py-2 px-4 bg-blue-600 text-white rounded' onClick={popup_handler}><i className='fa-solid fa-plus mr-2'></i>Add Domain</button>
                    </div>
                </div>
                <hr className='mb-7'></hr>
                {
                    domainList ? domainList.map(domain => <DOMAIN_LIST name={domain.domain_name} id={domain.id} domain={domain}/>) : null
                }
            </div>
            {open ? <>
            <ADD_DOMAIN_POPUP /> <div className="cancel-button-container flex justify-center">
                <button onClick={e => { setOpen(false) }} className="bg-red-700 p-2 px-5 text-white absolute bottom-7 rounded">Cancel <i className="fa-solid fa-xmark"></i></button>
            </div></> : null}
        </>
    )
}

export default Domain