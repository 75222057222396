export default (editor, opts = {})=> {
     const dc = editor.DomComponents;

     dc.addType(opts.name, {
        model: {
            traits: [
                {
                    type: 'text',
                    name: 'beforeText',
                    label: 'Before Text',
                    changeProp: 1,
                    placeholder: 'simple text'
                },
                {
                    type: 'text',
                    name: 'animatedText',
                    label: 'Before Text',
                    changeProp: 1,
                    placeholder: 'simple text'
                },
                {
                    type: 'text',
                    name: 'afterText',
                    label: 'Before Text',
                    changeProp: 1,
                    placeholder: 'simple text'
                }
            ],

            defaults: {
            }
        },
        isComponent: (el) => {
            if(el.className && el.className.includes("animated-btn")){
                return{
                    type: opts.name,
                }
            }
        }
     })
}