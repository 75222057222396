import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import DOMAIN_HOME_SETTER from './DOMAIN_HOME_SETTER';
import {Link} from 'react-router-dom'

   import { deleteDomain } from '../../Actions/Domain';

const DOMAIN_LIST = ({name, id, domain}) => {
   const [open,setOpen] = useState();


  return (
    <>

<ul class="w-full divide-y divide-gray-200 dark:divide-gray-700">
   <li class="mb-3 sm:mb-4 bg-gray-100 rounded-xl  px-5 py-3 border border-salte-200 ">
      <div class="flex items-center space-x-4">
         <div class="flex-1 min-w-0">
            <h4 className='text-xl'>{name}</h4>
         </div>
         <div class="inline-flex items-center text-base font-semibold ">
         <button onClick={() => {setOpen(true)}} className='bg-blue-600 px-5 text-sm rounded py-2.5 text-white mr-4 hover:bg-blue-700'>+ Change/ Add Homepage</button>
         <button onClick={e => {deleteDomain(id)}}>
            <i  className="fa-solid fa-trash mr-4 text-red-600 p-3 rounded bg-red-100 border border-red-200"></i>
         </button>
         <Link>
            <i className="fa-solid fa-eye mr-0 text-blue-800 p-3 rounded bg-blue-100 border border-blue-200"></i>
         </Link>
         </div>
      </div>
   </li>
</ul>
{open ? <>
            <DOMAIN_HOME_SETTER name={name} id={id} domain={domain}/> <div className="cancel-button-container flex justify-center">
                <button onClick={e => { setOpen(false) }} className="bg-red-700 p-2 px-5 text-white fixed bottom-7 rounded">Cancel <i className="fa-solid fa-xmark"></i></button>
            </div></> : null}

    </>
  )
}

export default DOMAIN_LIST