import React, { useEffect, useState } from 'react'
import DB_HEADER from '../components/dashboard/DB_HEADER'
import { Navigate as Redirect, Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import DB_LP_LIST from '../components/dashboard/DB_LP_LIST'
import NewsCard from '../components/NewsCard'
import DP_LP_CARD from '../components/dashboard/DP_LP_CARD'
import { loadPageList } from '../Actions/Page'
import CREATE_NEW_POPUP from '../components/dashboard/CREATE_NEW_POPUP'

const Dashboard = () => {

  const [open, setOpen] = useState()

  const { isAuthenticated } = useSelector((state) => state.user);
  const {pages:pageList} = useSelector((state)=> state?.page?.pageList)

  return (
    <>
    <DB_HEADER/>
      <div className="container mx-auto py-4 flex md:flex-row flex-col justify-center md:px-0 px-2 gap-4  ">
        <div className="bg-yellow-200 border border-dashed p-3 border-yellow-700 flex flex-row align-middle w-full md:w-1/2">
          <div className='w-7/12 md:w-7/12 flex items-center md:text-md text-sm'><p>Master the Art of <b>Building Landing Pages</b></p></div>
          <button className='w-5/12  md:w-5/12 bg-yellow-700 text-white px-2 py-2 rounded md:text-md text-sm hover:bg-yellow-900'>Free Course <i class="fa-solid fa-right-long ml-2"></i></button>
        </div>
        <div className="bg-blue-200 border border-dashed p-3 border-blue-700 w-full flex flex-row align-middle w-full md:w-1/2">
          <div className='w-7/12 md:w-7/12 flex items-center md:text-md text-sm'><p><b>Need Inspirations?</b> Check our Community!</p></div>
          <button className='w-5/12  md:w-5/12 bg-blue-700 text-white px-2 py-2 rounded md:text-md text-sm hover:bg-blue-900'>Browse Pages <i class="fa-solid fa-right-long ml-2"></i></button>
        </div>



      </div>
      <hr></hr>
      <div className="container mx-auto pt-5 flex justify-start md:px-0 px-2 gap-4">
        <div className="bg-gradient-to-r from-sky-500/20 to-pink-400/10 border border-dashed p-2 border-pink-700/40 align-middle w-full md:w-5/12 bg-grid-slate-700/25 flex flex-col gap-2 ">
          <div className="columns-2 pt-1 pb-2">
            <h3 className='p-2 mb-0.5 text-xl mb-2 font-medium'>Landing Pages</h3>
            <div className="flex justify-end"><Link to="/landing-pages" className="mt-1 px-4 py-2 h-full border-gray border rounded-full text-sm bg-white">View All</Link></div>
          </div>
          {
              pageList.length>0 ? pageList.slice(0,5).map(page => <DB_LP_LIST title={page.name} id={page.id} />) : null
            }
          <button onClick={e=> {setOpen(true)}} className="cursor-pointer border-blue-400 rounded-full mt-4 border-dashed border p-4 text-center bg-gray-50/70"><i className="fa-solid fa-plus text-blue-500"></i> Add New Page</button>
        </div>
        <div className="border border-gray-300 border-dashed bg-slate-100 p-3 w-full">
          <div className="columns-2 pt-0 pb-2">
            <h3 className='p-2 mb-0.5 text-xl mb-2 font-medium'>Latest News!</h3>
            <div className="flex justify-end"><button className="mt-1 px-4 py-2 h-full border-gray border rounded-full text-sm bg-white">View All</button></div>
          </div>
          <div class="w-full flex gap-6 snap-x snap-mandatory overflow-x-auto pb-14 relative">
            <div class="snap-center shrink-0 w-5/12">
              <NewsCard img="https://images.unsplash.com/photo-1604999565976-8913ad2ddb7c?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=320&h=160&q=80" title="How to Run profitable Ads for your Digital Product" category="Guide" />
            </div>
            <div class="snap-center shrink-0 w-5/12">
              <NewsCard img="https://images.unsplash.com/photo-1604999565976-8913ad2ddb7c?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=320&h=160&q=80" title="How to Run profitable Ads for your Digital Product" category="Guide" />
            </div>
            <div class="snap-center shrink-0 w-5/12">
              <NewsCard img="https://images.unsplash.com/photo-1604999565976-8913ad2ddb7c?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=320&h=160&q=80" title="How to Run profitable Ads for your Digital Product" category="Guide" />
            </div>
            <div class="snap-center shrink-0 w-5/12">
              <NewsCard img="https://images.unsplash.com/photo-1604999565976-8913ad2ddb7c?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=320&h=160&q=80" title="How to Run profitable Ads for your Digital Product" category="Guide" />
            </div>

          </div>
        </div>
      </div>


{/***Add New Popup */}

{
          open ? <><CREATE_NEW_POPUP /> <div className="cancel-button-container flex justify-center">
      <button onClick={e => { setOpen(false) }} className="bg-red-700 p-2 px-5 text-white absolute bottom-7 rounded">Cancel <i className="fa-solid fa-xmark"></i></button>
      </div></> : null
    }

    </>
  )
}

export default Dashboard