import React, { useState } from 'react'
import { useDispatch } from 'react-redux';
import { updateDomain } from '../../Actions/Domain';

const DOMAIN_HOME_SETTER = ({name,  domain}) => {
    const dispatch = (useDispatch())
    const [disabled, setDisabled] = useState(true);
  return (
    <div class="fixed bg-white rounded-xl p-4 shadow-md top-1/2 border border-gray-200 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-11/12 md:w-8/12 lg:w-6/12">
        <h3 className="text-md text-slate-800 text-xl mt-3 mb-4 relative">
          Select Home Page for <b>{name}</b>
        </h3>
        
        <hr className='mt-2 mb-4'></hr>
        <div className='flex gap-5'>
        <select className='w-1/2 px-4 py-2 rounded border border-gray-300' onChange={()=> {setDisabled(false)}}>
            <option value="null">None</option>
            {
                domain.domain_slugs.map(option => {
                    return <option vlaue={option._id}>{option.slug}</option> 
                })
            }
        </select>
        <div className='w-1/2'> 
            <button onClick={()=>{dispatch(updateDomain(domain._id, domain))}} disabled={disabled} className='px-3 py-2 bg-pink-500 w-full rounded text-white disabled:opacity-50 disabled:cursor-default'>Save</button>
        </div>
        </div>
    </div>
  )
}

export default DOMAIN_HOME_SETTER