import { createReducer } from "@reduxjs/toolkit";
const initialState = {};

export const pageReducer = createReducer(initialState, {
    CreatePageRequest : (state) => {
        state.loading = true;
    },
    CreatePageSuccess: (state, action) => {
        state.loading = false;
        state.page = action.payload;
        window.open("/editor/" + action.payload.data.page._id, '_blank');
    },
    CreatePageFailure: (state, action) => {
        state.loading = false;
        state.error = action.payload;
    },
    LoadPageListRequest : (state) => {
        state.loading = true;
    },
    LoadPageListSuccess: (state, action) => {
        state.loading = false;
        state.pageList = action.payload;
    },
    LoadPageListFailure: (state, action) => {
        state.loading = false;
        state.error = action.payload;
    }, 
    DeletePafeRequest : (state) => {
        state.loading = true;
    },
    DeletePageSuccess: (state, action) => {
        state.loading = false;
        state.page = action.payload;
    },
    DeletePageFailure: (state, action) => {
        state.loading = false;
        state.error = action.payload;
    }
})