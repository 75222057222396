import React from 'react'
import Footer from '../components/Footer'
import Header from '../components/Header'
import Login from '../components/Login'

const LoginPage = () => {
  return (
    <>
        <Header/>
        <Login/>
        <Footer/>
    </>
  )
}

export default LoginPage