import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { deletePageById, loadPageList } from '../../Actions/Page'
import DELETE_CONFIRMATION from './DELETE_CONFIRMATION';

const DP_LP_CARD = ({ title, id, tag, link = "https://adymize.com/", published, date }) => {
    const [open, setOpen] = useState(false);
    const dispatch = useDispatch();



    return (
        <>
            <div className="bg-yellow-100 border-dashed border-yellow-700 border p-3 relative">
                <iframe src={link} title={title} className="w-full h-64" />
                <a href={`/editor/${id}`} target='_blank' className="block text-md text-slate-800 text-xl mt-3 mb-4 relative ">
                    {title}
                    {/* <i onClick={e => { !open ? setOpen(true) : setOpen(false) }} className={open ? "fa-solid fa-xmark text-xl cursor-pointer w-24 text-right pr-2 absolute right-0" : "text-xl cursor-pointer w-24 text-right pr-2  fa-solid fa-ellipsis absolute right-0"}></i>

                    <div id="dropdown" className={open ? `absolute z-10 w-full top-6 text-base list-none bg-white rounded divide-y divide-gray-100 shadow border border-gray-100` : "hidden"} >
                        <ul className="py-0">
                            <li>
                                <a className="block py-2 px-4 text-sm text-gray-700 hover:bg-blue-100 "><i className="text-blue-600 mr-2 fa-solid fa-eye"></i>View</a>
                            </li>
                            <li>
                                <a className="block py-2 px-4 text-sm text-gray-700 hover:bg-green-100"><i className="text-green-600 mr-2 fa-solid fa-pen-to-square"></i> Edit</a>
                            </li>
                            <li>
                                <DELETE_CONFIRMATION id={id}/>
                            </li>
                        </ul>
                    </div> */}
                </a>
                <div className='flex flex-row gap-3'>
                    <button className='bg-red-300 h-10 w-10 rounded-full' onClick={() => {dispatch(deletePageById(id)); dispatch(loadPageList())}}><i className="fa-solid fa-trash-can text-red-900"></i></button>
                    <a className='bg-blue-300 h-10 w-10 rounded-full p-2' href={`http://localhost:8000/${id}`}target='_blank'><i className="fa-solid fa-eye text-blue-900"></i></a>
                    <button className='bg-green-300 h-10 w-10 rounded-full' onClick={() => {window.open(`/editor/${id}`, '_blank')}}><i className="fa-solid fa-pen text-green-900"></i></button>
                </div>
                <div className="columns-2 border-t border-yellow-900/50 pt-3 mt-3">
                    <div className="time-holder text-sm flex align-center items-center">
                        <i className="fa-solid fa-clock mr-2 text-blue-500 mr-2 text-xl"></i> {date}
                    </div>
                    <div className="published-holder text-sm flex align-center items-center">
                        {/*published ? <><i className="fa-solid fa-circle-check text-green-500 mr-2 text-xl"></i> Published</> : <><i className="fa-solid fa-circle-check text-green-300 mr-2"></i> Draft</>*/}
                    </div>
                    <div className="badge rounded-full absolute top-2 right-2 bg-blue-700 text-white py-1 text-xs px-3">
                        {tag}
                    </div>
                </div>
            </div>
        </>
    )
}

export default DP_LP_CARD