import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { addDomain, loadDomainList } from '../../Actions/Domain';

const CREATE_NEW_POPUP = ({ open = false }) => {

  const [name, setName] = useState('');

  const dispatch = useDispatch();

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(addDomain(name));
  }


  return (
    <>
      <div class="absolute bg-white rounded-xl p-4 shadow-md top-1/2 border border-gray-200 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-11/12 md:w-8/12 lg:w-6/12">
        <h3 className="text-md text-slate-800 text-xl mt-3 mb-4 relative">
          Add New Domain
        </h3>
        <hr className='mt-2 mb-4'></hr>
        <label>Domain Address<span className='text-red-600 pl-0.5'>*</span></label>
        <input type="text" value={name} onChange={e => { setName(e.target.value) }} className='w-full p-2 rounded-full mt-2 border-gray-200 border mb-3 px-4'></input>
        <p className='text-sm text-slate-500 mb-2'>Please use <span className='text-green-600'>demo.tld</span> please avoid <span className='text-red-600'>http://mytld.com/</span></p>
        <button onClick={e => { handleSubmit(e) }} className="mt-3 w-full p-3 bg-blue-600 text-white rounded-full text-xl cursor-pointer">
          Add Domain <i className="fa-solid fa-pen text-md ml-3"></i>
        </button>
      </div>

    </>
  )
}

export default CREATE_NEW_POPUP