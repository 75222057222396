import grapesjs from "grapesjs";
import axios from 'axios';
import './style.css'
import 'grapesjs-preset-webpage/dist/grapesjs-preset-webpage.min.css';
import 'grapesjs/dist/css/grapes.min.css';
import tabs from'grapesjs-tabs'
import 'grapesjs-blocks-basic'
import 'grapesjs-blocks-flexbox'
import code from 'grapesjs-custom-code'
import tailwind from 'grapesjs-tailwind';
import 'grapesjs-blocks-bootstrap4'
import gradient from 'grapesjs-style-gradient'

import { storageSetting } from "./ge_utils";
import animatedButton from "./plugins/Animated-button";

const escapeName = (name) => `${name}`.trim().replace(/([^a-z0-9\w-:/]+)/gi, '-');


const geditorConfig = (id) => {


  const editor = grapesjs.init({
    container: '#gjs',
    height: '100vh',
    width: '100%',
    plugins: [tabs, "gjs-blocks-basic", "gjs-blocks-flexbox",gradient, code, "gjs-plugin-ckeditor", tailwind],
    storageManager: storageSetting(id),
    selectorManager: { escapeName },
    showStylesOnChange: true,
    deviceManager: {
      devices:
      [
        {
          id: 'desktop',
          name: 'Desktop',
          width: '',
        },
        {
          id: 'tablet',
          name: 'Tablet',
          width: '768px',
          widthMedia: '992px',
        },
        {
          id: 'mobilePortrait',
          name: 'Mobile portrait',
          width: '320px',
          widthMedia: '575px',
        },
      ]
    },
    pluginsOpts: {
        'gjs-blocks-flexbox': {},
        'grapesjs-tabs': {},
        "gjs-blocks-basic": {},
        blocks: ['link-block', 'quote', 'text-basic'],
      },
    canvas: {
      styles: ["https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Jost:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap"],
      scripts: ["https://cdn.tailwindcss.com"]
    }
  });


  return editor;


};





export default geditorConfig;
