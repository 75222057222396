import React, { useEffect, useState } from 'react'
import CREATE_NEW_POPUP from '../components/dashboard/CREATE_NEW_POPUP'
import DB_HEADER from '../components/dashboard/DB_HEADER'
import DP_LP_CARD from '../components/dashboard/DP_LP_CARD'
import { useSelector, useDispatch } from 'react-redux'
import { loadPageList } from '../Actions/Page'
import Loader from '../components/Loader'

const LandingPages = () => {
    const dispatch = useDispatch();
    const [open, setOpen] = useState(false);

    const dateConverter = (data)=>{
        var date = new Date(data);
        return(date.toDateString())
    }
    let pageList = [];
    pageList = useSelector(state => state?.page?.pageList?.pages);
    console.log(pageList)

    return (
        <>
        <DB_HEADER/>
            <div className="container mx-auto py-4 px-3 md:px-0">
                <div className="columns-2  mb-9">
                    <div className="heading-container">
                        <h3 className="md:text-3xl text-xl mt-2 mb-6">Landing Pages</h3>
                    </div>
                    <div className="create-container flex justify-end">
                        <button className='py-2 px-4 bg-blue-600 text-white rounded' onClick={e => { setOpen(true) }}><i className='fa-solid fa-plus mr-2'></i>Add Page</button>
                    </div>
                </div>
                <hr ></hr>
                <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-8 mt-3'>
                    {
                        pageList?.length> 0 ? pageList.slice(0).reverse().map(page=> <DP_LP_CARD key={page._id} link={"http://localhost:8000/"+ page._id} id={page._id} title={page.name} tag={page.tags} date={dateConverter(page.updatedAt)}/>) : null
                    }
                </div>
            </div>
            {open ? <><CREATE_NEW_POPUP /> <div className="cancel-button-container flex justify-center">

                <button onClick={e => { setOpen(false) }} className="bg-red-700 p-2 px-5 text-white absolute bottom-7 rounded">Cancel <i className="fa-solid fa-xmark"></i></button>
            </div></> : null}
        </>
    )
}

export default LandingPages