
import React, { useEffect } from 'react'
import grapesjs from 'grapesjs'
import 'grapesjs/dist/css/grapes.min.css'
import 'grapesjs/dist/grapes.min.js'
import 'grapesjs-preset-webpage/dist/grapesjs-preset-webpage.min.css'
import 'grapesjs-preset-webpage/dist/grapesjs-preset-webpage.min.js'
import { useParams } from 'react-router'
import { useDispatch } from 'react-redux';
import {savePage} from "./Actions/Page";



  
const editor = grapesjs.init({
// Indicate where to init the editor. You can also pass an HTMLElement
container: '#gjs',
// Get the content for the canvas directly from the element
// As an alternative we could use: `components: '<h1>Hello World Component!</h1>'`,
// Size of the editor
height: '100vh',
width: 'auto',
plugins: ['gjs-preset-webpage'],
storageManager: {
    id: 'gjs-',             // Prefix identifier that will be used inside storing and loading
    type: 'local',          // Type of the storage
    autosave: false,         // Store data automatically
    autoload: true,         // Autoload stored data on init
    stepsBeforeSave: 1,     // If autosave enabled, indicates how many changes are necessary before store method is triggered
    storeComponents: true,  // Enable/Disable storing of components in JSON format
    storeStyles: true,      // Enable/Disable storing of rules in JSON format
    storeHtml: true,        // Enable/Disable storing of components as HTML string
    storeCss: true,         // Enable/Disable storing of rules as CSS string
  },
  deviceManager: {
    devices:
    [
      {
        id: 'desktop',
        name: 'Desktop',
        width: '',
      },
      {
        id: 'tablet',
        name: 'Tablet',
        width: '768px',
        widthMedia: '992px',
      },
      {
        id: 'mobilePortrait',
        name: 'Mobile portrait',
        width: '320px',
        widthMedia: '575px',
      },
    ]
  },
  pluginsOpts: {
    'grapesjs-preset-webpage': {
      blocksBasicOpts: {
        blocks: ['column1', 'column2', 'column3', 'column3-7', 'text',     'link', 'image', 'video'],
        flexGrid: 1,
      },
      blocks: ['link-block', 'quote', 'text-basic'],
    },
  }
});


const PageEditor = () => {
      const {id} = useParams();
      const handleSave = () => {
        const content = editor.getHtml();
        dispatch(savePage(id, content));
      }
      const dispatch = useDispatch();
      useEffect(() => {
        editor.load();
      }, []);
    return (
      <>
        <div id='gjs'></div>
        <button className="bg-blue-600 text-white py-2 px-4" onClick={handleSave}>Save</button>
      </>
    )
}

export default PageEditor