import React from 'react'
import {Link} from 'react-router-dom'
import { useDispatch, useSelector } from "react-redux";
import { logoutUser } from '../../Actions/User';
import { loadPageList } from '../../Actions/Page';


const DB_Header = () => {
  const dispatch = useDispatch();

  const { isAuthenticated } = useSelector((state) => state.user);

  const handleLogout = () => {
    dispatch(logoutUser());
  }


  return (
    <>

<nav className="bg-white border-gray-200 py-3 dark:bg-gray-900 border-b">
  <div className="container flex flex-wrap justify-between items-center mx-auto md:p-0 px-3">
    <a href="https://flowbite.com/" className="flex items-center">
        
        <span className="self-center text-xl font-semibold whitespace-nowrap dark:text-white">Logo</span>
    </a>
    <button data-collapse-toggle="navbar-default" type="button" className="inline-flex items-center p-2 ml-3 text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600" aria-controls="navbar-default" aria-expanded="false">
      <span className="sr-only">Open main menu</span>
      <svg className="w-6 h-6" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clip-rule="evenodd"></path></svg>
    </button>
    <div className="hidden w-full md:block md:w-auto" id="navbar-default">
      <ul className="flex flex-col p-4 mt-4 bg-gray-50 rounded-lg border border-gray-100 md:flex-row md:space-x-8 md:mt-0 md:text-sm md:font-medium md:border-0 md:bg-white dark:bg-gray-800 md:dark:bg-gray-900 dark:border-gray-700">
        <li>
          <Link to='/dashboard' className='block py-2 pr-4 pl-3 text-white bg-blue-700 rounded md:bg-transparent md:text-slate-900 md:dark:text-white md:p-0 dark:text-white'>Dashboard</Link>
        </li>
        <li>
          <Link to='/landing-pages' className='block py-2 pr-4 pl-3 text-white bg-blue-700 rounded md:bg-transparent md:text-slate-900 md:dark:text-white md:p-0 dark:text-white'>Landing Pages</Link>
        </li>
        <li>
          <Link to='/domain' className='block py-2 pr-4 pl-3 text-white bg-blue-700 rounded md:bg-transparent md:text-slate-900 md:dark:text-white md:p-0 dark:text-white'>Domains</Link>
        </li>
        <li>
          <Link to='/' className='block py-2 pr-4 pl-3 text-white bg-blue-700 rounded md:bg-transparent md:text-slate-900 md:dark:text-white md:p-0 dark:text-white'>Billing</Link>
        </li>
        <li>
          <Link to='/' className='block py-2 pr-4 pl-3 text-white bg-blue-700 rounded md:bg-transparent md:text-slate-900 md:dark:text-white md:p-0 dark:text-white'>Docs</Link>
        </li>
        <li>
          <button onClick={handleLogout} className='block py-2 pr-4 pl-3 text-white bg-blue-700 rounded md:bg-transparent md:text-slate-900 md:dark:text-white md:p-0 dark:text-white'>Logout</button>
        </li>
      </ul>
    </div>
  </div>
</nav>

    </>
  )
}

export default DB_Header