import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from "react-redux";
import { loginUser } from '../Actions/User';
import { Navigate as Redirect } from 'react-router-dom';

const Login = () => {
    const [email, setEmail] = useState();
    const [password, setPassword] = useState("");
    const [remember, setRemember] = useState();
    // const [ready, setReady] = useState(false);
    const [validation, setValidation] = useState();

    const dispatch = useDispatch();


    const checkboxChange = () => { 
        setRemember(!remember); 
      };
    
    const emailChange = (e) =>{
        setEmail(e.target.value);
        
    }

    const passwordChange = (e)=>{
        setPassword(e.target.value)
    }

    // const handleReady = () => {
        
    //     if (/^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/.test(email)) { 
    //         if(password.length > 7){
    //             setReady(true);
    //             if (ready){
    //                 document.getElementById("loginBtn").setAttribute("disabled", "false");

    //             }
    //         }else{
    //             setReady(false)
    //         }
    //      }
    //      else{
    //         setReady(false)
    //      }
    // }

        const { error } = useSelector((state) => state.user);
        const { isAuthenticated } = useSelector((state) => state.user);
   

    if(isAuthenticated){
        <Redirect to="/dashboard" replace={true} />
    }
    const submitHandler = (e) =>{
        if (!(/^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/.test(email))) {
            setValidation("Please Enter Correct Email!");
            document.getElementById("errorBox").classList.remove("hidden");

        }else if(!(password.length > 7)){
            setValidation("Please Enter Correct Password!")
            document.getElementById("errorBox").classList.remove("hidden");

        }else{
            document.getElementById("errorBox").classList.add("hidden");
            dispatch(loginUser(email, password))
            
            if(isAuthenticated){
                document.getElementById("errorBox").classList.add("hidden");
                
                
            }else if(error){

                setValidation(error)
                document.getElementById("errorBox").classList.remove("hidden");
                
            }
        }
    }

  return (
    <>
    {isAuthenticated ? window.location.replace("/dashboard") : null}
    <div className="mx-auto  flex flex-wrap justify-center gap-0 max-h-10/12  ">
        <div className="px-8 md:px-24 pt-12 md:pb-0 pb-8 flex justify-center flex-col align-middle md:w-4/6 lg:w-1/2">
            <h3 className='text-4xl text-gray-800 font-bold mb-3 block'>Login</h3>
            <p className='text-md text-gray-500 mb-8 border-b pb-3 border-gray-200'>Let's Get most out of your product and Service</p>
            <div className="bg-red-200 border-dashed border border-red-500 mb-2 p-3 hidden" id="errorBox">
            <i class="fa-solid fa-triangle-exclamation text-red-600 pr-2 border-r border-red-300 mr-2"></i> {validation}
            </div>
            <div className="login-form pb-3 mt-2">
                <label className='text-md'>Email <span className="text-red-800">*</span></label>
                <input type="email" value={email} onChange={e => {emailChange(e)}} className='border border-slate-200 rounded-full w-full py-2 px-4 mt-2'></input>
            </div>
            <div className="login-form pb-3 mt-2">
                <label className='text-md'>Password <span className="text-red-800">*</span></label>
                <input type="password" value={password} onChange={ e => {passwordChange(e)} } className='border border-slate-200 rounded-full w-full py-2 px-4 mt-2'></input>
            </div>
            <div className="columns-2 pt-2 mb-3">
                <div className="remember-me-conatainer">
                    <input type="checkbox" checked={remember} onChange={checkboxChange} name="" className="mr-1 text-pink-600" id="remember-me" ></input><label htmlFor="remember-me">Remember Me</label>
                </div>
                <div className="forgot-password-container flex justify-end">
                    <a href="#" className='text-pink-600 hover:text-pink-800'>Forgot Password</a>
                </div>
            </div>
            <button onClick={e => {submitHandler(e)}} className="p-3 mt-3 w-full bg-gradient-to-r from-pink-500 to-red-500 text-white rounded-full mb-2 font-medium  text-lg">Login In</button>
            <p className="text-gray-600 mt-2">Not Registered yet? <a href="#" className='text-pink-600'>Pick a Plan</a></p>
        </div>
        <img className='bg-indigo-500 p-6 w-full h-screen object-contain md:w-2/6 lg:w-1/2 ' src="./images/login.svg"></img>
    </div>
    </>
  )
}

export default Login