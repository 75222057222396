import React, { useEffect, useState } from 'react'
import { loadPageList } from '../../Actions/Page';
import { useSelector, useDispatch } from 'react-redux';

const DB_LP_list = ({ title }) => {
    const [open, setOpen] = useState(false);
    


    return (

        <>
            <div className="flex p-3 bg-white border-gray-200 border-2 hover:mb-1 hover:-mt-1 hover:shadow relative">
                <div className="w-full text-sm flex items-center    ">
                    {title}
                </div>
                <div className="absolute left-0 w-64 flex justify-end align-center relative ">
                    <i onClick={e => { !open ? setOpen(true) : setOpen(false) }} className={open? "fa-solid fa-xmark text-xl cursor-pointer w-24 text-right pr-2 " : "text-xl cursor-pointer w-24 text-right pr-2  fa-solid fa-ellipsis "}></i>
                    <div id="dropdown" className={open ? `absolute z-10 w-full top-6 text-base list-none bg-white rounded divide-y divide-gray-100 shadow border border-gray-100` : "hidden"} >
                        <ul className="py-0">
                            <li>
                                <a href="#" className="block py-2 px-4 text-sm text-gray-700 hover:bg-blue-100 "><i className="text-blue-600 mr-2 fa-solid fa-eye"></i>View</a>
                            </li>
                            <li>
                                <a href="#" className="block py-2 px-4 text-sm text-gray-700 hover:bg-green-100"><i className="text-green-600 mr-2 fa-solid fa-pen-to-square"></i> Edit</a>
                            </li>
                            <li>
                                <a href="#" className="block py-2 px-4 text-sm text-red-600 hover:bg-red-100"><i className=" text-red-600 mr-2 fa-solid fa-trash"></i> Delete</a>
                            </li>
                        </ul>
                    </div>
                </div>


            </div>
        </>
    )
}

export default DB_LP_list