import axios from "axios";
import {host } from '../config/host'

export const loadDomainList = ( ) => async (dispatch) => {
    try {
        dispatch({
            type: "LoadDomainListRequest",
        });

        const data = await axios.get(`${host}/api/v1/domain/list`);

        dispatch({
            type: "LoadDomainListSuccess",
            payload: data,
        });
    }
    catch (error) {
        dispatch({
        type: "LoadDomainListFailure",
        payload: error.response.data.message,
        });
    }
}

export const addDomain = (domain) => async (dispatch) => {


    try {
        dispatch({
        type: "AddDomainRequest",
        });
        const domainObj = {domain_name: domain}

        const data = await axios.post(
        `${host}/api/v1/domain/addDomain`,
        domainObj,
        {
            headers: {
            "Content-Type": "application/json",
            },
        }
        );
        window.location.replace("/domain")
    
        dispatch({
            type: "AddDomainSuccess",
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: "AddDomainFailure",
            payload: error,
        });
        console.log(error);
    }

}


export const updateDomain = (id, domain) => async (dispatch) => {
    try{
        dispatch({type: "UpdateDomainRequest"});

        const res = await axios.put(`${host}/api/v1/domain/${id}`,
        domain,
        {
            headers: {
            "Content-Type": "application/json",
            },
        })

        dispatch({type: "UpdateDomainSuccess"})
    }catch(error){
        dispatch({type: "UpdateDomainFailure", payload: error})
    }
}


export const deleteDomain = (id) => async (dispatch) => {
    try{
        dispatch({type: "deleteDomainRequest"})
        const res = await axios.delete(`${host}/api/v1/domain/${id}`, {
            headers:{
                "Content-Type": "application/json",
            }
        })
        dispatch({type: "deleteDomainSuccess"})
    }catch(error){
        dispatch({type: "deleteDomainFailure", payload: error})
    }
}