import React from 'react'

const Header = () => {
  return (

    <header className="bg-white  border-b border-slate-200 py-3">
      <div className="header-container container py-2 mx-auto">

     
      <div className="nav-container flex justify-center align-middle">
        <div className="logo-container align-middle">
          LOGO
        </div>
      </div>
      </div>
    </header>
  )
}

export default Header