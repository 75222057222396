import grapesjs from "grapesjs";
import loadComponents from './component'
import loadBlocks from './blocks'

export default grapesjs.plugins.add("animatedButton", (editor, opts)=>{
    let options = {
        label: "Pro Button",
        name: "animatedButton",
        category: "Professional",
    }

    for(let name in options){
        if(!(name in opts)) opts[name] = options[name]
    }

    loadBlocks(editor, opts);
    loadComponents(editor, opts)
})