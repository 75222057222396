import React, {useState} from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { createPage, loadPageList } from '../../Actions/Page';

const CREATE_NEW_POPUP = ({open = false}) => {

  const [name, setName] = useState('');
  const [slug, setSlug] = useState('');
  const [tags, setTag] = useState('');
  const [domain, setDomain] = useState('');

  const dispatch = useDispatch();
  const domainList = useSelector((state) => state.domain.domainList.data.domains);

  const handleSubmit = async (e) => {
    const page = {name, slug, tags, domain, content: {}, homepage: null};
    dispatch(createPage(page));
    dispatch(loadPageList())
  }


  return (
    <>
    <div class="absolute bg-white rounded-xl p-4 shadow-md top-1/2 border border-gray-200 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-11/12 md:w-8/12 lg:w-6/12"> 
            <h3 className="text-md text-slate-800 text-xl mt-3 mb-4 relative">
                Create new page
            </h3>
            <hr className='mt-2 mb-4'></hr>
            <label>Name<span className='text-red-600 pl-0.5'>*</span></label>
            <input type="text" value={name} onChange={e => {setName(e.target.value)}} className='w-full p-2 rounded-full mt-2 border-gray-200 border mb-3 px-4'></input>
            <label>Slug<span className='text-red-600 pl-0.5 '>*</span></label>
            <input type="text" value={slug} onChange={e => {setSlug(e.target.value)}} className='w-full p-2 rounded-full mt-2 border-gray-200 border mb-3 px-4'></input>
            <label>Tag<span className='text-red-600 pl-0.5'>*</span></label>
            <input type="text" value={tags} onChange={e => {setTag(e.target.value)}} className='w-full p-2 rounded-full mt-2 border-gray-200 border mb-4 px-4'></input>
            <div className="domain-container flex justify-between">
            <label>Select Domain<span className='text-red-600 pl-0.5'>*</span></label>
            <a href="" className='text-pink-700 hover:text-pink-500'>+ Add New Domain</a>
            </div>
            <div className="select-wraper mb-4">
                <select value={domain} onChange={e=> {setDomain(e.target.value)}} required type="text" className='w-full p-2 rounded-full mt-2 border-gray-200 border px-4'>
                <option value="defaut">Select Domain</option>
                {domainList.map(domain => <option value={domain._id}>{domain.domain_name}</option>)}
                </select>
            </div>
            <button onClick={e => {handleSubmit(e)}} className="mt-3 w-full p-3 bg-blue-600 text-white rounded-full text-xl">
                Start Editing <i className="fa-solid fa-pen text-md ml-3"></i>
            </button>
        </div> 
        
    </>
  )
}

export default CREATE_NEW_POPUP