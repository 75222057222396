import React, { useEffect, useState } from "react";
import { useParams } from "react-router";

import geditorConfig from "./editor_config/ge_config";

const Editor = () => {
 const [editor, setEditor] = useState(null);
  const {id }=  useParams()
  useEffect(() => {
    const editor = geditorConfig(id);
    setEditor(editor);
  }, []);
 
  return (
     <div id="gjs"></div>

  );
};

export default Editor;
