import React from 'react'

const News_card = ({img, title, category}) => {
  return (
    <div className="bg-white shadow rounded-xl border border-gray-200 p-3 relative">
        <img className='h-40 object-cover w-full rounded-md' src={img}></img>
        <h3 className='text-md font-medium mt-3'>
            {title}
        </h3>
        <span className='bg-blue-600 text-white text-sm absolute top-5 right-5 rounded px-3 py-0.5'>{category}</span>
        <p className='text-slate-500 mt-1 text-sm'>Hey This Article Will teach you about how to run Ads for DP</p>
        <a href="" className="px-5 inline-block bg-gradient-to-r from-sky-400 to-blue-500 py-1 rounded text-white mt-3 hover:from-blue-700 hover:to-blue-900">Read More<i className="ml-2 fa-solid fa-right-long"></i></a>
    </div>
  )
}

export default News_card