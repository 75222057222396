import React from 'react'

const Footer = () => {
  return (
    <footer className='border-t border-gray-200 text-center p-2'>
        Copyright 2022 - XYZ
    </footer>
  )
}

export default Footer