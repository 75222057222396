import { createReducer } from "@reduxjs/toolkit";

const initialState = {};

export const domainReducer = createReducer(initialState, {
    AddDomainRequest : (state) => {
        state.loading = true;
    },
    AddDomainSuccess: (state) => {
        state.loading = false;
    },
    AddDomainFailure: (state, action) => {
        state.loading = false;
        state.error = action.payload;
    },
    LoadDomainListRequest : (state) => {
        state.loading = true;
    },
    LoadDomainListSuccess: (state, action) => {
        state.loading = false;
        state.domainList = action.payload;
    },
    LoadDomainListFailure: (state, action) => {
        state.loading = false;
        state.error = action.payload;
    },
    UpdateDomainRequest: (state) => {
        state.loading = true;
    },
    UpdateDomainSuccess: (state) => {
        state.loading = false;
    },
    UpdateDomainFailure: (state, action)=>{
        state.loading = false;
        state.eroor = action.payload;
    }
});
