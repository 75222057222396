export default (editor, opts ={})=> {
    const bm = editor.BlockManager;

    const style = `
    <style>
    .animated-btn{
        background: yellow;
    }
    </style>

    `

    bm.add(opts.name, {
        label: `
        <?xml version="1.0" ?><svg style="enable-background:new 0 0 64 64;" version="1.1" viewBox="0 0 64 64" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><style type="text/css">
	.st0{fill:none;stroke:#ffffff;stroke-width:2;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;}
	.st1{fill:none;stroke:#ffffff;stroke-width:2;stroke-miterlimit:10;}
</style><g id="Layer_1"/><g id="Layer_2"><g><g><path class="st0" d="M59.2,27.4c1.3,2,2.1,4.4,2.1,6.9v0c0,3.7-1.6,7-4.1,9.3"/><path class="st0" d="M46.1,47H20.4c-4.5,0-8.4-2.3-10.7-5.8"/></g><g><path class="st0" d="M6.7,20.4C9,18.3,12.1,17,15.4,17h2.3"/><path class="st0" d="M24.2,17h19.4c7,0,12.7,5.7,12.7,12.7v0c0,7-5.7,12.7-12.7,12.7H15.4c-7,0-12.7-5.7-12.7-12.7v0     c0-0.8,0.1-1.6,0.2-2.4"/></g></g></g></svg>
        <div class="gjs-block-label">${opts.label}</div>
        `,
        category: opts.category,
        content: `<div class="animated-btn">My Animated Button</div>`
    })
}