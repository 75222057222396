import axios from "axios";
import {toast} from 'react-toastify'
import "react-toastify/dist/ReactToastify.css"
import {host} from '../config/host'


const notify = (errorMsg) => {
    toast(errorMsg)
}

export const savePage = (page, id) => async (dispatch) => {
    try {
        dispatch({
        type: "SaveRequest",
        });
    
        const { data } = await axios.post(
        `${host}/api/v1/${id}/content`,
        data,
        {
            headers: {
            "Content-Type": "application/json",
            },
        }
        );
    
        dispatch({
        type: "SaveSuccess",
        });
    } catch (error) {
        notify(error.message)
        dispatch({
        type: "SaveFailure",
        payload: error.response.data.message,
        });
        
    }
    }


export const createPage = (page) => async (dispatch) => {
    try {
        dispatch({
        type: "CreatePageRequest",
        });
        const data = await axios.post(
        `/api/v1/page/newpage`,
        page,
        {
            headers: {
            "Content-Type": "application/json",
            },
        }
        );
    
        dispatch({
        type: "CreatePageSuccess",
        payload: data,
        });
    } catch (error) {
        dispatch({
        type: "CreatePageFailure",
        payload: error.response.data.message,
        });
    }
}

export const loadPageList = (id) => async (dispatch) => {
    try {
        dispatch({
        type: "LoadPageListRequest",
        });
        const data = await axios.get(
        `${host}/api/v1/page/pages`
        );
    
        dispatch({
        type: "LoadPageListSuccess",
        payload: data.data,
        });
    }
    catch (error) {
        dispatch({
        type: "LoadPageListFailure",
        payload: error.response.data.message,
        });
    }
}

export const deletePageById = (id) => async (dispatch) => {
    try {
        dispatch({
        type: "DeletePageRequest",
        });
        const data = await axios.delete(
        `${host}/api/v1/page/${id}`
        );
    
        dispatch({
        type: "DeletePageSuccess",
        payload: data,
        });
    }
    catch (error) {
        dispatch({
        type: "DeletePageFailure",
        payload: error.response.data.message,
        });
    }
    
}
